import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { Handle } from '@xyflow/react';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { Body7, Container } from '../../../libs/nvstr-common-ui.es';
import { useColorTheme } from '../../../hooks';
import styled from 'styled-components';

const ContentWrapper = styled(NodeContentWrapper)`
  min-width: 180px;
  width: 180px;
`;

export const StartNode = ({ data, id }) => {
  const colorTheme = useColorTheme();

  React.useEffect(() => {
    console.log({ data });
  }, [data]);

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'Start'} />
      <ContentWrapper>
        <Container>
          <Body7>Drag down to start and select your first node.</Body7>
        </Container>
      </ContentWrapper>
      <Handle type="source" position="bottom" />
    </NodeWrapper>
  );
};
