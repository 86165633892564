import { COMPANY_LIST_PROMPT_PREFIX, NODE_HANDLE_TYPES, NODE_TYPES } from '../containers/flow/nodes';
import { streamResult } from '../hooks/features/useStreamingResult';
import { getResultId } from '../hooks/features/useAskDigitalAnalyst';
import { AI_TOOLS, CURRENT_DEFAULT_MODEL } from '../constants';
import { logger } from '../utils/usefulFuncs';
import { parseJSONResultCompanyList } from '../utils/result';

function findNodeById(id, nodes) {
  return nodes.find((n) => n.id === id);
}

function findDirectParentTargetNodes(node, nodes, edges) {
  const directEdges = edges.filter((edge) => edge.target === node.id);
  const connectedNodes = directEdges.map((edge) => nodes.find((n) => n.id === edge.source));
  return connectedNodes.filter(Boolean);
}

function findChildrenNodes(node, nodes, edges) {
  const childEdges = edges.filter((edge) => edge.target === node.id && edge.targetHandle === NODE_HANDLE_TYPES.source);
  const childNodes = childEdges.map((edge) => nodes.find((n) => n.id === edge.source));
  return childNodes.filter(Boolean);
}

function gatherNodeContext(node, nodes, edges) {
  const contextNodes = [];

  function traceParents(currentNode) {
    const incomingEdges = edges.filter((edge) => edge.target === currentNode.id);
    incomingEdges.forEach((edge) => {
      const parentNode = findNodeById(edge.source, nodes);
      if (
        parentNode &&
        parentNode.data &&
        (parentNode.data.answer || parentNode.data.answers || parentNode.data.context)
      ) {
        contextNodes.push(parentNode);
      }
      if (parentNode) {
        traceParents(parentNode);
      }
    });
  }

  traceParents(node);
  return contextNodes;
}

function gatherContextFromNodes(nodesWithContext) {
  let gatheredContext = '';
  nodesWithContext.forEach((node) => {
    const { answer, answers, context } = node.data;
    if (answer) {
      gatheredContext += ' ';
      gatheredContext += answer;
    }
    if (answers) {
      Object.entries(answers).forEach((entry) => {
        const [companyName, answer] = entry;
        gatheredContext += ' ';
        gatheredContext += companyName;
        gatheredContext += ' ';
        gatheredContext += answer;
      });
    }
  });
  return gatheredContext;
}

function buildNodeExecutionOrder(node, nodes, edges) {
  const parentNodes = [];

  function traceBack(currentNode) {
    const incomingEdges = edges.filter((edge) => edge.target === currentNode.id);
    incomingEdges.forEach((edge) => {
      const parentNode = findNodeById(edge.source, nodes);
      if (parentNode) {
        parentNodes.push(parentNode);
        traceBack(parentNode);
      }
    });
  }

  traceBack(node);
  return parentNodes;
}

function getOriginalNodeActions(node, nodes) {
  return nodes.filter((n) => n.id === node.id)[0].data.actions;
}

async function executeNode(node, nodeActions, state) {
  console.log('execute node: ' + node.type, { node, state });

  switch (node.type) {
    case NODE_TYPES.ForEachCompanyNode: {
      const { nodes, edges } = state;
      const children = findChildrenNodes(node, nodes, edges);
      const nodeContext = gatherNodeContext(node, nodes, edges);
      const parentNodes = findDirectParentTargetNodes(node, nodes, edges);

      const companies = [];

      const executeQuestionForCompany = async (company) => {
        const extraContext = gatherContextFromNodes(nodeContext);
        const model = CURRENT_DEFAULT_MODEL;

        const question = node.data.question;
        const params = {
          ticker: company.symbol,
          tool: AI_TOOLS.QUERY_ENGINE,

          extraContext,
          model,

          enableContext: true,
          enableMath: false,
          enableFollowUpQuestions: false,
          enableTextSearch: false,

          includeInferredMetadata: true,
        };
        const { id: resultId, error } = await getResultId(question, params, {});

        if (error) {
          node.data.error = error;
          nodeActions.updateData({ error });
          logger('error getting result', node);
          return false;
        }

        const onOpen = () => {
          if (!('answers' in node.data)) {
            node.data.answers = {};
          }
          node.data.answers[company.symbol] = 'Working...';
          nodeActions.updateAnswers({ [company.name + ' ' + company.symbol]: 'Working...' });
        };
        const onMessage = (answer) => {
          node.data.answers[company.symbol] = answer;
          nodeActions.updateAnswers({ [company.name + ' ' + company.symbol]: answer });
        };
        const onRefiningStart = () => {
          node.data.isRefining = true;
          nodeActions.updateData({ isRefining: true });
        };
        const onError = (error) => {
          node.data.error = error;
          nodeActions.updateData({ error });
        };
        const onComplete = ({ answer }) => {
          node.data.answers[company.symbol] = answer;
          nodeActions.updateAnswers({ [company.name + ' ' + company.symbol]: answer });
        };
        const handlers = {
          onOpen,
          onMessage,
          onRefiningStart,
          onError,
          onComplete,
        };
        const options = { enableQualityCheck: true };
        await streamResult(resultId, handlers, options);
      };

      parentNodes.forEach((node) => {
        try {
          const answer = node.data.answer;
          const list = parseJSONResultCompanyList(answer);
          if (list) {
            list.forEach((c) => companies.push(c));
          }
        } catch (e) {
          logger('error parsing company list node');
        }
      });

      const requests = companies.map((company) => {
        return executeQuestionForCompany(company);
      });
      await Promise.all(requests);
      console.log({ parentNodes, children, companies });
      return true;
    }
    case NODE_TYPES.CompanyListNode: {
      const { nodes, edges } = state;
      const children = findChildrenNodes(node, nodes, edges);
      const nodeContext = gatherNodeContext(node, nodes, edges);
      const documentListNodes = children.filter((n) => n.type === NODE_TYPES.DocListNode);
      const selectedDocs = [];
      documentListNodes.forEach((n) => {
        n.data.selectedDocs.forEach((doc) => {
          selectedDocs.push(doc);
        });
      });

      const extraContext = gatherContextFromNodes(nodeContext);
      const model = CURRENT_DEFAULT_MODEL;

      const question = COMPANY_LIST_PROMPT_PREFIX + node.data.question;
      const params = {
        selectedDocs,
        screen_mode: selectedDocs.length === 0,

        tool: AI_TOOLS.QUERY_ENGINE,

        extraContext,
        model,
        // maxAnswerLength,
        // maxContextLength,
        // maxContextChunks,
        // seed,
        // temperature,
        // topP,

        enableContext: true,
        enableMath: false,
        enableFollowUpQuestions: false,
        enableTextSearch: false,

        includeInferredMetadata: true,

        // recordId,
      };
      const { id: resultId, error } = await getResultId(question, params, {});

      if (error) {
        node.data.error = error;
        nodeActions.updateData({ error });
        logger('error getting result', node);
        return false;
      }

      const onOpen = () => {
        node.data.answer = 'Working...';
        nodeActions.updateData({ answer: 'Working...' });
      };
      const onMessage = (answer) => {
        node.data.answer = answer;
        nodeActions.updateData({ answer });
      };
      const onRefiningStart = () => {
        node.data.isRefining = true;
        nodeActions.updateData({ isRefining: true });
      };
      const onError = (error) => {
        node.data.error = error;
        nodeActions.updateData({ error });
      };
      const onComplete = ({ answer }) => {
        node.data.answer = answer;
        nodeActions.updateData({ answer });
      };
      const handlers = {
        onOpen,
        onMessage,
        onRefiningStart,
        onError,
        onComplete,
      };
      const options = { enableQualityCheck: true };
      await streamResult(resultId, handlers, options);
      return true;

      return true;
    }
    case NODE_TYPES.PromptNode: {
      const { nodes, edges } = state;
      const children = findChildrenNodes(node, nodes, edges);
      const nodeContext = gatherNodeContext(node, nodes, edges);
      const documentListNodes = children.filter((n) => n.type === NODE_TYPES.DocListNode);
      const selectedDocs = [];
      documentListNodes.forEach((n) => {
        n.data.selectedDocs.forEach((doc) => {
          selectedDocs.push(doc);
        });
      });

      const extraContext = gatherContextFromNodes(nodeContext);
      const model = CURRENT_DEFAULT_MODEL;

      const question = node.data.question;
      const params = {
        selectedDocs,
        screen_mode: selectedDocs.length === 0,

        tool: AI_TOOLS.QUERY_ENGINE,

        extraContext,
        model,
        // maxAnswerLength,
        // maxContextLength,
        // maxContextChunks,
        // seed,
        // temperature,
        // topP,

        enableContext: true,
        enableMath: false,
        enableFollowUpQuestions: false,
        enableTextSearch: false,

        includeInferredMetadata: true,

        // recordId,
      };
      const { id: resultId, error } = await getResultId(question, params, {});

      if (error) {
        node.data.error = error;
        nodeActions.updateData({ error });
        logger('error getting result', node);
        return false;
      }

      const onOpen = () => {
        node.data.answer = 'Working...';
        nodeActions.updateData({ answer: 'Working...' });
      };
      const onMessage = (answer) => {
        node.data.answer = answer;
        nodeActions.updateData({ answer });
      };
      const onRefiningStart = () => {
        node.data.isRefining = true;
        nodeActions.updateData({ isRefining: true });
      };
      const onError = (error) => {
        node.data.error = error;
        nodeActions.updateData({ error });
      };
      const onComplete = ({ answer }) => {
        node.data.answer = answer;
        nodeActions.updateData({ answer });
      };
      const handlers = {
        onOpen,
        onMessage,
        onRefiningStart,
        onError,
        onComplete,
      };
      const options = { enableQualityCheck: true };
      await streamResult(resultId, handlers, options);
      return true;
    }

    default:
      break;
  }

  return false;
}

function slowClone(obj) {
  const str = JSON.stringify(obj);
  return JSON.parse(str);
}

export const executeFlowQuery = async (outputNode, nodes, edges) => {
  try {
    console.log('---------------------------------');
    console.log('run executeFlowQuery', { outputNode, nodes, edges });
    console.log('---------------------------------');
    const mutableNodes = slowClone(nodes);
    const mutableEdges = slowClone(edges);

    const executionOrder = buildNodeExecutionOrder(outputNode, mutableNodes, mutableEdges).reverse();

    console.log('executionOrder', executionOrder);

    for (const node of executionOrder) {
      const state = {
        nodes: mutableNodes,
        edges: mutableEdges,
      };
      const nodeActions = getOriginalNodeActions(node, nodes);
      await executeNode(node, nodeActions, state);
    }

    outputNode.data.actions.updateData({ isComplete: true });
    console.log('execution complete');
  } catch (e) {
    logger('error while outputting', e);
    outputNode.data.actions.updateData({ didError: true });
  }
};
