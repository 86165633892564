import React from 'react';
import styled from 'styled-components';
import { Header } from '../components/navigation';
import { Body } from '../components/layout/Body';
import { addEdge, Background, Controls, ReactFlow, useEdgesState, useNodesState } from '@xyflow/react';
import { Page } from '../libs/nvstr-common-ui.es';
import '@xyflow/react/dist/style.css';
import {
  createFlowNode,
  EDGE_TYPES,
  generateCreationContextFrom,
  NODE_COMPONENT_TYPES,
  NODE_HANDLE_TYPES,
  NODE_TYPES,
  SOURCE_ONLY_NODE_TYPES,
} from '../containers/flow/nodes';
import { executeFlowQuery } from '../services/flowEngine';
import { useColorTheme } from '../hooks';
import { DocSelectionModalController } from '../containers/flow/DocSelectionModalController';

const PageWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;
const FlowWrapper = styled.div`
  position: relative;
  height: calc(98vh - 106px);
  width: 100%;
  max-width: 1440px;

  .react-flow__handle {
    min-height: 8px;
    min-width: 20px;

    border-radius: 3px;
  }

  .react-flow__handle-right,
  .react-flow__handle-left {
    min-height: 20px;
    min-width: 8px;
  }
`;

const startNode = createFlowNode(NODE_TYPES.StartNode);
startNode.position = { x: 24, y: 24 };
const initialNodes = [startNode];
const initialEdges = [];

let cachedNodes = initialNodes;
let cachedEdges = initialEdges;
let connectionStartSourceNode = null;

const defaultEdgeOptions = { type: 'custom' };

export function QueryEngine() {
  const colorTheme = useColorTheme();

  const [docSelectionModalInstance, setDocSelectionModalInstance] = React.useState(null);
  const [reactFlowInstance, setReactFlowInstance] = React.useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [viewport, setViewport] = React.useState({ x: 0, y: 0, zoom: 1 });

  const onMove = React.useCallback((event, { x, y, zoom }) => {
    setViewport({ x, y, zoom });
  }, []);

  React.useEffect(() => {
    console.log({ edges, nodes });
  }, [nodes, edges]);

  const connectionLineStyle = {
    stroke: colorTheme.text,
    strokeWidth: 3,
    strokeDasharray: '10,4',
  };

  const onConnect = React.useCallback((params) => {
    return setEdges((eds) => addEdge(params, eds));
  }, []);

  const replaceNode = (sourceNodeId, currentNodeId, type, params) => {
    let nodeToReplace = null;
    setNodes((prevState) => {
      const updatedNodes = prevState.filter((node) => node.id !== currentNodeId);
      nodeToReplace = prevState.filter((node) => node.id === currentNodeId)[0];
      return updatedNodes;
    });

    const newNode = createFlowNode(type);
    newNode.position = nodeToReplace.position;
    onAddNode(newNode);

    const isSourceOnlyNode = SOURCE_ONLY_NODE_TYPES.includes(newNode.type);
    const originIsFromTargetNode = params.handleType === NODE_HANDLE_TYPES.defaultTarget;
    const newEdge = {
      id: `e${sourceNodeId}-${newNode.id}`,
    };
    if (isSourceOnlyNode || originIsFromTargetNode) {
      newEdge.source = newNode.id;
      newEdge.target = sourceNodeId;
    } else {
      newEdge.source = sourceNodeId;
      newEdge.target = newNode.id;
    }
    if ([NODE_TYPES.PromptNode, NODE_TYPES.CompanyListNode, NODE_TYPES.ForEachCompanyNode].includes(newNode.type))
      newEdge.targetHandle = NODE_HANDLE_TYPES.target;
    if (isSourceOnlyNode) newEdge.targetHandle = NODE_HANDLE_TYPES.source;
    setEdges((eds) => addEdge(newEdge, eds));
  };

  const onAddNode = (node) => {
    const getNewNodeOptimalPosition = (nodes) => {
      const lastNode = nodes[nodes.length - 1];
      const lastNodePosition = lastNode?.position || { x: 24, y: 24 };

      return {
        x: lastNodePosition.x,
        y: lastNodePosition.y + 460,
      };
    };

    setNodes((prevNodes) => {
      node.data.actions.updateData = (data) => updateNodeData(node.id, data);
      node.data.actions.updateAnswer = (answer) => updateNodeData(node.id, { answer });
      node.data.actions.updateAnswers = (data) => updateNodeAnswersData(node.id, data);

      node.data.actions.onRemove = () => onRemove(node.id);
      node.data.actions.onRemoveAllLinks = () => onRemoveAllLinks(node.id);

      if (node.type === NODE_TYPES.OutputNode) {
        node.data.actions.onRunOutput = () => onRunOutput(node.id);
        node.data.actions.onClearAnswers = () => onClearAnswers(node.id);
      }
      if (node.type === NODE_TYPES.DocListNode) {
        node.data.actions.onShowDocumentSelection = () => docSelectionModalInstance.showModal(node.id);
        node.data.selectedDocs = [];
      }
      if (node.type === NODE_TYPES.CreateNode) {
        node.data.actions.replaceNode = (sourceNodeId, currentNodeId, type, params) =>
          replaceNode(sourceNodeId, currentNodeId, type, params);
      }

      if (!node.position) {
        const { x, y } = getNewNodeOptimalPosition(prevNodes);
        node.position = { x, y };
      }

      return [...prevNodes, node];
    });
  };

  const onConnectStart = React.useCallback(
    (event, params) => {
      console.log('onConnectStart', { event, params });
      const node = nodes.find((n) => n.id === params.nodeId);
      connectionStartSourceNode = node;
      const { handleId, handleType } = params;
      const handleParams = {
        handleId,
        handleType,
      };
      connectionStartSourceNode.handleParams = handleParams;
    },
    [nodes]
  );

  const onConnectEnd = (event) => {
    const { target } = event;

    const connectedToNodeId = target?.getAttribute('data-nodeid');
    const connectedToNode = nodes.find((node) => node.id === connectedToNodeId); // to find the node that was connected to

    if (!connectedToNode && !!connectionStartSourceNode) {
      const creationContext = generateCreationContextFrom(connectionStartSourceNode);
      if (creationContext) {
        const { clientX, clientY } = event;
        const canvasX = (clientX - viewport.x) / viewport.zoom - 80;
        const canvasY = (clientY - viewport.y) / viewport.zoom - 80;
        const position = { x: canvasX, y: canvasY };
        const newNode = createFlowNode(NODE_TYPES.CreateNode);
        newNode.data.sourceNodeId = connectionStartSourceNode.id;
        newNode.position = position;
        newNode.data.creationContext = creationContext;
        newNode.data.connectionStartSourceNode = connectionStartSourceNode;
        onAddNode(newNode);
      }
    }

    connectionStartSourceNode = null;
  };

  const focusNode = (nodeId) => {
    const node = nodes.find((n) => n.id === nodeId);
    if (node && reactFlowInstance) {
      reactFlowInstance.setCenter(node.position.x, node.position.y, { zoom: 1.5 });
    }
  };

  React.useEffect(() => {
    cachedNodes = nodes;
    cachedEdges = edges;

    return () => {
      cachedNodes = [];
      cachedEdges = [];
      connectionStartSourceNode = null;
    };
  }, [nodes, edges]);

  const onDocSelectionModalInit = (ref) => {
    setDocSelectionModalInstance(ref);
  };

  const onRunOutput = (nodeId) => {
    executeFlowQuery(
      cachedNodes.find((n) => n.id === nodeId),
      cachedNodes,
      cachedEdges
    );
  };

  const onRemove = (nodeId) => {
    setNodes((prevNodes) => prevNodes.filter((f) => f.id !== nodeId));
  };

  const onRemoveAllLinks = (nodeId) => {
    setEdges((prevEdges) =>
      prevEdges.filter((e) => {
        if (e.target === nodeId) return false;
        if (e.source === nodeId) return false;
        return true;
      })
    );
  };

  const updateNodeData = (id, data) => {
    setNodes((prevNodes) => {
      const index = prevNodes.findIndex((f) => f.id === id);
      const nextState = [...prevNodes];
      nextState[index] = {
        ...nextState[index],
        data: {
          ...nextState[index].data,
          ...data,
        },
      };
      return nextState;
    });
  };

  const onClearAnswers = () => {
    setNodes((prevNodes) => {
      const nextState = prevNodes.map((n) => {
        const updatedNode = { ...n, data: { ...n.data } };
        updatedNode.data.answer = null;
        updatedNode.data.answers = {};
        if (updatedNode.type === NODE_TYPES.OutputNode) {
          updatedNode.data.isComplete = false;
        }
        return updatedNode;
      });
      return nextState;
    });
  };

  const updateNodeAnswersData = (id, data) => {
    setNodes((prevNodes) => {
      const index = prevNodes.findIndex((f) => f.id === id);
      const nextState = [...prevNodes];
      nextState[index] = {
        ...nextState[index],
        data: {
          ...nextState[index].data,
          answers: {
            ...(nextState[index].data.answers || {}),
            ...data,
          },
        },
      };
      return nextState;
    });
  };

  return (
    <>
      <Header />
      <Body withFooter>
        <PageWrapper>
          <Page width={'1440px'} noPadding>
            <DocSelectionModalController
              onInit={onDocSelectionModalInit}
              nodes={nodes}
              updateNodeData={updateNodeData}
            />
            <FlowWrapper>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={NODE_COMPONENT_TYPES}
                edgeTypes={EDGE_TYPES}
                defaultEdgeOptions={defaultEdgeOptions}
                connectionLineStyle={connectionLineStyle}
                onInit={setReactFlowInstance}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onConnectStart={onConnectStart}
                onConnectEnd={onConnectEnd}
                onMove={onMove}
              >
                <Controls />
                <Background variant="dots" gap={12} size={1} />
              </ReactFlow>
            </FlowWrapper>
          </Page>
        </PageWrapper>
      </Body>
    </>
  );
}
