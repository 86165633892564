import React from 'react';
import { TrackingEvent } from '../../libs/nvstr-utils.es';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { useAdvancedSettings } from '../application/useAdvancedSettings';
import { logFormSubmit, logNetRequest } from '../../utils/usefulFuncs';
import { addOptionalBoolField, addOptionalField, addOptionalFreeField } from '../../utils/application';

export async function getResultId(question, params, options) {
  const {
    selectedDocs,
    screen_mode,
    ticker,

    tool,

    extraContext,
    recordId,

    model,
    maxAnswerLength,
    maxContextLength,
    maxContextChunks,
    seed,
    temperature,
    topP,

    enableMath,
    enableFollowUpQuestions,
    enableTextSearch,
    enableContext,

    includeInferredMetadata,
  } = params;
  const { isResearchMode, isAPICitationsMode } = options;

  const form = isAPICitationsMode
    ? {
        record_id: recordId,
        user_mode: tool,
      }
    : {
        question,
        model,
        generate_follow_ups: enableFollowUpQuestions,
        user_mode: tool,
        doc_list: selectedDocs?.map((doc) => doc.id),
        screen_mode,
        research_mode: isResearchMode,
        need_context: enableContext,
        enable_text_search: !!enableTextSearch,
      };

  addOptionalFreeField('extra_context', extraContext, form);
  addOptionalFreeField('ticker', ticker, form);

  addOptionalField('max_answer_tokens', maxAnswerLength, form);
  addOptionalField('max_context_tokens', maxContextLength, form);
  addOptionalField('max_context_chunks', maxContextChunks, form);
  addOptionalField('seed', seed, form);
  addOptionalField('temperature', temperature, form, true);
  addOptionalField('top_p', topP, form, true);

  addOptionalBoolField('use_inferred_metadata', includeInferredMetadata, form);
  addOptionalBoolField('enable_math_correction', enableMath, form);

  logFormSubmit(form);

  TrackingEvent.create('Asked Digital Analyst Question', {
    Question: question,
    Model: model,
    Tickers: ticker,
    'Selected Docs': selectedDocs?.map((d) => d.filename),
    'Inserting Citations': isAPICitationsMode,
  });

  const URL = isAPICitationsMode ? `v1/genai_qa_citation/stream` : `v1/genai_qa/stream`;
  const { status, data, error } = await sendApiRequest('post', URL, form);
  logNetRequest(status, data, error);
  handle400Statuses(status);
  if (status === 200) {
    const { id } = data;
    return { id };
  } else {
    TrackingEvent.create('Asked Digital Analyst Question Failed', {
      Question: question,
      'Inserting Citations': isAPICitationsMode,
      Error: data?.error || error || 'unknown',
    });
    return {
      error: data?.error || error || 'Something went wrong, try again.',
    };
  }
}

export const useAskDigitalAnalystSubmit = (tool) => {
  const { model, maxContextLength, maxAnswerLength, enableMath, maxContextChunks, seed, temperature, topP } =
    useAdvancedSettings();

  const onSubmit = React.useCallback(
    async ({
      prompt,
      docIds,
      selectedDocs,

      enableTextSearch,
      extraContext,
    }) => {
      TrackingEvent.create('Asked Digital Analyst Question', {
        Question: prompt,
        Model: model,
        'Selected Docs': selectedDocs?.map((d) => d.filename),
      });

      const form = {
        question: prompt,
        model: model,
        user_mode: tool,
        doc_list: docIds || selectedDocs?.map((doc) => doc.id),
        need_context: true,
        use_inferred_metadata: true,
        enable_text_search: !!enableTextSearch,
      };
      if (extraContext) {
        form.extra_context = extraContext;
      }
      addOptionalField('max_context_tokens', maxContextLength, form);
      addOptionalField('max_answer_tokens', maxAnswerLength, form);
      addOptionalField('max_context_chunks', maxContextChunks, form);
      addOptionalField('seed', seed, form);
      addOptionalField('temperature', temperature, form, true);
      addOptionalField('top_p', topP, form, true);
      addOptionalBoolField('enable_math_correction', enableMath, form);

      logFormSubmit(form);

      const URL = `v1/genai_qa/stream`;
      const { status, data, error } = await sendApiRequest('post', URL, form);
      logNetRequest(status, data, error);
      handle400Statuses(status);
      if (status === 200) {
        const { id } = data;
        return {
          resultId: id,
          form,
        };
      } else {
        TrackingEvent.create('Asked Digital Analyst Question Failed', {
          Question: prompt,
          Error: data?.error || error || 'unknown',
        });
        return {
          error: data?.error || error || 'Something went wrong, try again.',
        };
      }
    },
    [tool]
  );

  return onSubmit;
};

export const useAskDigitalAnalyst = (tool) => {
  const {
    model,
    seeContext,
    enableFollowUpQuestions,
    maxContextLength,
    includeInferredMetadata,
    maxAnswerLength,
    seed,
    maxContextChunks,
    enableMath,
    temperature,
    topP,
  } = useAdvancedSettings();

  const [prevUsedModel, setPrevUsedModel] = React.useState(model);
  const [resultId, setResultId] = React.useState(null);
  const [citationsResultId, setCitationsResultId] = React.useState(null);
  const [questionAsked, setQuestionAsked] = React.useState(null);
  const [sourceDocs, setSourceDocs] = React.useState([]);

  const [formError, setFormError] = React.useState(null);
  const [isSubmittingToApi, setIsSubmittingToApi] = React.useState(false);

  const onSubmit = async ({
    question,
    forceQuestionAsked,
    selectedDocs,
    forceFollowUpQuestionOff,
    ticker,
    isResearchMode,
    model: forceModel,
    enableTextSearch,
    extra_context,
    isCitationsMode,
    record_id,
    isAPICitationsMode,
  }) => {
    if (selectedDocs?.length === 0 && !isAPICitationsMode) {
      setFormError('Please select at least one ticker or document to run a query');
      return;
    }
    if (question === '' && !isAPICitationsMode) {
      setFormError('Please enter a question to run a query on the selected tickers/documents.');
      return;
    }

    if (!isCitationsMode) {
      setSourceDocs(selectedDocs);
    }
    const _question = question;
    const _model = forceModel || model;
    setPrevUsedModel(_model);
    if (!isCitationsMode) {
      setCitationsResultId(null);
    }
    const form = isAPICitationsMode
      ? {
          record_id: record_id,
          user_mode: tool,
        }
      : {
          question: _question,
          model: _model,
          generate_follow_ups: forceFollowUpQuestionOff ? false : enableFollowUpQuestions,
          user_mode: tool,
          doc_list: selectedDocs?.map((doc) => doc.id),
          research_mode: isResearchMode,
          need_context: seeContext,
          use_inferred_metadata: includeInferredMetadata || false,
          enable_text_search: !!enableTextSearch,
        };
    if (extra_context) {
      form.extra_context = extra_context;
    }
    if (ticker) {
      form.ticker = ticker;
    }
    addOptionalField('max_answer_tokens', maxAnswerLength, form);
    addOptionalField('max_context_tokens', maxContextLength, form);
    addOptionalField('max_context_chunks', maxContextChunks, form);
    addOptionalField('seed', seed, form);
    addOptionalField('temperature', temperature, form, true);
    addOptionalField('top_p', topP, form, true);
    addOptionalBoolField('enable_math_correction', enableMath, form);

    logFormSubmit(form);

    TrackingEvent.create('Asked Digital Analyst Question', {
      Question: _question,
      Model: _model,
      Tickers: ticker,
      'Selected Docs': selectedDocs?.map((d) => d.filename),
      'Inserting Citations': isAPICitationsMode,
    });

    setFormError(false);
    if (!isCitationsMode) {
      setResultId(null);
    }
    setIsSubmittingToApi(true);
    setQuestionAsked(forceQuestionAsked || question);

    const URL = isAPICitationsMode ? `v1/genai_qa_citation/stream` : `v1/genai_qa/stream`;
    const { status, data, error } = await sendApiRequest('post', URL, form);
    setIsSubmittingToApi(false);
    logNetRequest(status, data, error);
    handle400Statuses(status);
    if (status === 200) {
      const { id } = data;
      isCitationsMode ? setCitationsResultId(id) : setResultId(id);
      setFormError(false);
    } else {
      if (!isCitationsMode) {
        setResultId(null);
      }
      setFormError(data?.error || error || 'Something went wrong, try again.');
      TrackingEvent.create('Asked Digital Analyst Question Failed', {
        Question: _question,
        'Inserting Citations': isAPICitationsMode,
        Error: data?.error || error || 'unknown',
      });
    }
  };

  const onClearResult = () => {
    setResultId(null);
    setCitationsResultId(null);
    setFormError(null);
    setSourceDocs([]);
  };

  return {
    onSubmit,
    onClearResult,
    sourceDocs,

    resultId,
    citationsResultId,

    prevUsedModel,
    questionAsked,
    formError,
    isSubmittingToApi,
  };
};
