import React from 'react';
import { TrackingEvent } from '../../../libs/nvstr-utils.es';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { useTagSearchResults } from '../../../hooks/features/useStockSearch';
import { CompanyInputTag, SelectableTags } from '../../form/TagInput';
import { CloseButton } from '../../../components/buttons/CloseButton';

const Label = styled.div`
  padding-bottom: 8px;
`;
const InputWrapper = styled.div`
  position: relative;
  min-width: 120px;
  flex: 1;
`;
export const CompanySelectionInputStyling = styled.div`
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  color: ${({ theme }) => theme.themeColors.text};
  background-color: transparent;
  border-radius: 6px;
  width: 100%;
  padding: 8px;
  cursor: text;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  input {
    font-size: 14px;
    border: none !important;
    padding: 4px 0 0 0 !important;
    outline: none;
    line-height: 18px;
  }
`;
const Wrapper = styled.div`
  //
`;
const CompanyInputSelectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.themeColors.border};
  padding: 4px 8px;
`;
const CloseButtonWrapper = styled.div`
  cursor: pointer;

  * {
    z-index: 6;
  }

  svg {
    height: 12px;
    width: 12px;
  }

  g {
    fill: ${({ theme }) => theme.themeColors.text};
  }
`;

const CompanyInputSelection = ({ value, onRemove }) => {
  return (
    <CompanyInputSelectionWrapper>
      <Container>
        <Body5>{value?.name}</Body5>
      </Container>
      <Container left={4}>
        <CloseButtonWrapper>
          <CloseButton onClick={onRemove} />
        </CloseButtonWrapper>
      </Container>
    </CompanyInputSelectionWrapper>
  );
};

let queuedBlur = null;

const queueBlur = (cb) => {
  queuedBlur = setTimeout(() => {
    cb();
  }, 200);
};

const CompanyInput = ({
  label,
  value,

  onAddTag,
  onRemoveTag,

  placeholder,
  enableCreateTag,
  enableRequestTicker,
  enableMultipleTickers,
}) => {
  const inputRef = React.useRef(null);
  const [inputValue, setInputValue] = React.useState('');
  const [isFocused, setIsFocused] = React.useState(false);
  const [selectResultIndex, setSelectResultIndex] = React.useState(null);

  const [isQuerying, selectableTags, activeQuery] = useTagSearchResults(inputValue, false, true);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value === ' ') {
      return;
    }
    setInputValue(value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    queueBlur(() => setIsFocused(false));
    // need delay otherwise clicks are missed
  };

  const handleTagSelect = (v) => {
    onAddTag(v);
    setInputValue('');
    setIsFocused(true);
    if (queuedBlur) {
      clearTimeout(queuedBlur);
    }
  };

  const handleInputClick = () => {
    inputRef?.current?.focus();
  };

  React.useEffect(() => {
    // always reset index on input change
    setSelectResultIndex(0);
  }, [inputValue]);

  React.useEffect(() => {
    const handleDeleteTagKeyBinds = (e) => {
      const key = e?.key?.toLowerCase();
      if (key === 'backspace' && value?.length > 0 && inputValue === '' && isFocused) {
        const tag = value[value.length - 1];
        onRemoveTag(tag);
      }
    };
    document.addEventListener('keydown', handleDeleteTagKeyBinds);
    return () => {
      document.removeEventListener('keydown', handleDeleteTagKeyBinds);
    };
  }, [isFocused, inputValue, onRemoveTag, inputRef, value]);

  React.useEffect(() => {
    const handleSelectSearchResultsKeyBinds = (e) => {
      const key = e?.key?.toLowerCase();

      if (['enter'].includes(key)) {
        const selectedTicker = selectableTags[selectResultIndex];
        if (selectedTicker && selectedTicker.length > 0) {
          handleTagSelect(selectedTicker);
        }
      }
    };
    document.addEventListener('keydown', handleSelectSearchResultsKeyBinds);
    return () => {
      document.removeEventListener('keydown', handleSelectSearchResultsKeyBinds);
    };
  }, [value, inputValue, selectableTags, selectResultIndex]);

  const handleRemoveSingleValueTag = () => {
    onRemoveTag(value);
  };

  if (enableMultipleTickers) {
    return (
      <Wrapper>
        {label ? (
          <Label>
            <Body5>{label}</Body5>
          </Label>
        ) : null}

        <CompanySelectionInputStyling onClick={handleInputClick}>
          {value?.map((t, i) => (
            <CompanyInputTag key={t} value={t} onRemoveTag={onRemoveTag} />
          ))}

          <InputWrapper>
            <input
              ref={inputRef}
              placeholder={placeholder}
              value={inputValue}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleInputChange}
            />
            <SelectableTags
              query={activeQuery}
              value={selectableTags}
              onClick={handleTagSelect}
              selectResultIndex={selectResultIndex}
              isFocused={isFocused}
              enableCreateTag={enableCreateTag}
              enableRequestTicker={enableRequestTicker}
            />
          </InputWrapper>
        </CompanySelectionInputStyling>
      </Wrapper>
    );
  }

  return (
    <>
      {label ? (
        <Label>
          <Body5>{label}</Body5>
        </Label>
      ) : null}

      {value === null ? (
        <CompanySelectionInputStyling onClick={handleInputClick}>
          <InputWrapper>
            <input
              ref={inputRef}
              placeholder={placeholder}
              value={inputValue}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleInputChange}
            />
            <SelectableTags
              query={activeQuery}
              value={selectableTags}
              onClick={handleTagSelect}
              selectResultIndex={selectResultIndex}
              isFocused={isFocused}
              enableCreateTag={enableCreateTag}
              enableRequestTicker={enableRequestTicker}
            />
          </InputWrapper>
        </CompanySelectionInputStyling>
      ) : (
        <CompanyInputSelection value={value} onRemove={handleRemoveSingleValueTag} />
      )}
    </>
  );
};

export const CompanySelectionInput = ({
  value,
  onAddCompany,
  onRemoveCompany,
  placeholder,
  label,
  multipleTickersEnabled,
  enableCreateTag,
}) => {
  const handleAddCompany = (t, n) => {
    TrackingEvent.create('Added Companies For Digital Analyst', {
      Companies: [t],
    });

    onAddCompany(t, n);
  };

  const handleRemoveCompany = (t, n) => {
    TrackingEvent.create('Removed Companies For Digital Analyst', {
      Companies: [t],
    });

    onRemoveCompany(t, n);
  };

  return (
    <CompanyInput
      label={!label ? null : multipleTickersEnabled ? 'Companies' : 'Company'}
      value={value}
      onAddTag={handleAddCompany}
      onRemoveTag={handleRemoveCompany}
      placeholder={placeholder}
      enableCreateTag={enableCreateTag}
    />
  );
};
