import React from 'react';
import { handle400Statuses, sendApiRequest } from '../../services/api';
import { logNetRequest } from '../../utils/usefulFuncs';
import { useDispatch, useSelector } from 'react-redux';
import { uploadStatusActions } from '../../constants/actionTypes';

function groupData(data) {
  const completed = [];
  const pending = [];
  const errored = [];
  data.forEach((d) => {
    const { title, status } = d;
    if (status === 'Completed') {
      completed.push(d);
    } else if (status === 'In progress') {
      pending.push(d);
    } else {
      if (status === 'Errored') {
        errored.push(d);
      }
    }
  });
  return { completed, pending, errored };
}

const POLL_DELAY = 10000;

export async function fetchUploadStatus(dispatch) {
  const URL = `v1/genai_user_crawl_jobs_get`;
  const { status, data } = await sendApiRequest('get', URL);
  handle400Statuses(status);
  logNetRequest(status, data);
  if (status === 200) {
    const groupedData = groupData(data);
    dispatch({
      type: uploadStatusActions.receiveData,
      payload: groupedData,
    });
  }
}

export function useUploadStatus() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.uploadStatus);

  React.useEffect(() => {
    fetchUploadStatus(dispatch);

    let interval = setInterval(() => {
      fetchUploadStatus(dispatch);
    }, POLL_DELAY);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return state;
}
