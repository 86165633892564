import styled from 'styled-components';
import { Body5, Container, Page } from '../../libs/nvstr-common-ui.es';
import { FlatButton } from '../../components/buttons';
import React from 'react';
import { AI_TOOLS, CURRENT_DEFAULT_MODEL } from '../../constants';
import { CompanyQuestionPromptsSummary } from './components/CompanyOverview';
import BackArrowButton from '../../components/buttons/BackArrowButton';
import { FormState } from '../../components/UI/FormState';
import { Spinner } from '../../components/UI/LoadingSpinner';
import { SkeletonButton } from '../../components/buttons/SkeletonButton';
import { ResultRatingForm } from '../form/ResultRatingForm';
import { PROMPT_SERIES_MODES, usePromptSeries } from '../../hooks/features/usePromptSeries';
import { AdvancedSettingsButton } from './AdvancedSettings';
import { AdvancedSettingsFields } from '../../hooks/application/useAdvancedSettings';
import { useDispatch } from 'react-redux';
import { applicationActionTypes } from '../../constants/actionTypes';
import { CompanySelectionInput } from './components/CompanySelectionInput';

const multipleTickersEnabled = false;

const PageWrapper = styled.div`
  + div {
    width: 100%;
  }
`;
const SubmitButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const FormWrapper = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 8px;
  }

  select {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    width: 100%;
    padding: 12px 16px;
    -webkit-appearance: auto !important;
  }

  textarea {
    border: 1px solid ${({ theme }) => theme.themeColors.border};
    color: ${({ theme }) => theme.themeColors.text};
    background-color: transparent;
    border-radius: 6px;
    min-height: 100px;
    width: 100%;
    padding: 16px;
  }
`;

const advancedSettingsFields = [
  // AdvancedSettingsFields.seeContext,
  AdvancedSettingsFields.model,
  AdvancedSettingsFields.maxContextLength,
  AdvancedSettingsFields.smoothingToggle,
  AdvancedSettingsFields.includeUserDocsToggle,
  AdvancedSettingsFields.maxContextChunks,
];

const defaultAdvancedSettings = {
  model: CURRENT_DEFAULT_MODEL,
  enableSmoothing: false,
  includeUserDocs: true,
  maxContextLength: '',
  maxAnswerLength: '',
};

const tool = AI_TOOLS.COMPANY_SNAPSHOT;

export default function CompanySnapshotTool({ onGoBack }) {
  const dispatch = useDispatch();
  const [company, setCompany] = React.useState(null);
  const [companyName, setCompanyName] = React.useState('');

  const { resultId, resultIdTicker, isSubmittingToApi, formError, onSubmit, onClear, questionList } =
    usePromptSeries(tool);

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });
  }, []);

  React.useEffect(() => {
    if (resultId) {
      const id = 'snapshot-answers-section';
      const yOffset = -26;
      const element = document.getElementById(id);
      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        setTimeout(() => {
          window.scrollTo({ top: y, behavior: 'smooth' });
        }, 300);
      }
    }
  }, [resultId]);

  const onGetCompanySummary = () => {
    onSubmit(company, PROMPT_SERIES_MODES.companySnapshot);
  };

  const onAddTicker = (t) => {
    setCompany(t);
    setCompanyName(t.name);
    onClear();
  };

  const onRemoveTicker = () => {
    setCompany(null);
  };

  const handleClearSnapshot = () => {
    onClear();
  };

  return (
    <PageWrapper>
      <Page width={resultId ? '1000px' : '800px'}>
        <BackArrowButton onClick={onGoBack} />
        {isSubmittingToApi ? (
          <Container top={80} bottom={80} centerAll>
            <Container>
              <Container centerAll>
                <Spinner />
              </Container>
              <Container top={24} left={12}>
                <Body5>Working...</Body5>
              </Container>
            </Container>
          </Container>
        ) : (
          <Container top={40}>
            <FormWrapper>
              <AdvancedSettingsButton fields={advancedSettingsFields} />
              <Container top={24}>
                <CompanySelectionInput
                  value={company}
                  label={null}
                  onAddCompany={onAddTicker}
                  onRemoveCompany={onRemoveTicker}
                  placeholder={'Search Companies...'}
                  alwaysHideClear
                />
              </Container>
              <Container top={24}>
                <Container centerAll>
                  <FormState error={formError} />
                </Container>
                <SubmitButtonWrapper>
                  <FlatButton disabled={company === null} onClick={onGetCompanySummary} fullWidth>
                    Get Snapshot
                  </FlatButton>
                </SubmitButtonWrapper>
              </Container>
            </FormWrapper>
          </Container>
        )}
      </Page>
      {resultId && (
        <Page width={'1000px'}>
          <div id={'snapshot-answers-section'} />
          <CompanyQuestionPromptsSummary
            questionList={questionList}
            companyName={companyName}
            ticker={resultIdTicker}
            resultId={resultId}
          />
          <ResultRatingForm resultId={resultId} />

          <Container top={24} centerAll>
            <SkeletonButton onClick={handleClearSnapshot}>Close Snapshot</SkeletonButton>
          </Container>
        </Page>
      )}
    </PageWrapper>
  );
}
