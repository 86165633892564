import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { Handle } from '@xyflow/react';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { FlatButton, TransparentFlatButton } from '../../../components/buttons';
import { Working } from '../../../components/UI/Working';
import { Body5, CheckCircle, Container } from '../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';

const CheckWrapper = styled.div`
  svg {
    height: 16px;
    width: 16px;

    path {
      fill: ${({ theme }) => theme.themeColors.text};
    }
  }
`;

export const OutputNode = ({ data, id }) => {
  const { isComplete } = data;
  const [isRunning, setIsRunning] = React.useState(false);

  const onRun = () => {
    setIsRunning(true);
    data.actions.onRunOutput();
  };

  const onClearAnswers = () => {
    setIsRunning(false);
    data.actions.onClearAnswers();
  };

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'Output'} actions={data.actions} />
      <NodeContentWrapper style={{ minWidth: 400, minHeight: 120 }}>
        {isComplete ? (
          <Container>
            <Container top={8} left={2} row verticallyCenter>
              <CheckWrapper>
                <CheckCircle />
              </CheckWrapper>
              <Container left={8} bottom={3}>
                <Body5 bold>Completed</Body5>
              </Container>
            </Container>
            <Container top={16}>
              <FlatButton fullWidth onClick={onRun}>
                Re-Run
              </FlatButton>
            </Container>
            <Container top={4}>
              <TransparentFlatButton fullWidth onClick={onClearAnswers}>
                Clear Answers
              </TransparentFlatButton>
            </Container>
          </Container>
        ) : isRunning ? (
          <Working />
        ) : (
          <FlatButton fullWidth onClick={onRun}>
            Run
          </FlatButton>
        )}
      </NodeContentWrapper>

      <Handle type="target" position="top" />
      <Handle type="source" position="bottom" />
    </NodeWrapper>
  );
};
