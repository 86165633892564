import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { Handle } from '@xyflow/react';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { Working } from '../../../components/UI/Working';
import { Answer } from '../../DigitalAnalyst/components/AnswerLine';
import { AI_TOOLS } from '../../../constants';
import { Body5, Body7, Container } from '../../../libs/nvstr-common-ui.es';
import { useColorTheme } from '../../../hooks';
import styled from 'styled-components';
import { NODE_HANDLE_TYPES } from './index';

const ContentWrapper = styled(NodeContentWrapper)`
  min-width: 500px;
  min-height: 220px;
`;

const Content = ({ id, data, value, onTextChange }) => {
  const { answer, error, isComplete } = data;

  if (error) {
    return (
      <ContentWrapper>
        <Container bottom={8}>
          <Body5>Something went wrong.</Body5>
        </Container>
        <Body5>{error}</Body5>
      </ContentWrapper>
    );
  }
  if (answer === 'Working...') {
    return (
      <ContentWrapper>
        <Container>
          <Body7>{value}</Body7>
        </Container>
        <Working />
      </ContentWrapper>
    );
  }
  if (answer) {
    return (
      <ContentWrapper>
        <Container>
          <Body5>Question Asked: {value}</Body5>
        </Container>
        <Answer
          result={answer}
          userMode={AI_TOOLS.QUERY_ENGINE}
          isComplete={isComplete}
          // isShowingCitations={isShowingCitations}
          // onCitationClick={onCitationClick}
          // isAddingCitations={citationsResultId && !citationsStreamEnded && citationsStreamingResult !== 'Working...'}
          // enableMoreDetail={enableMoreDetail && streamEnd}
          // model={model}
          // selectedDocs={selectedDocs}
          // sourceDocs={sourceDocs}
          // tickers={tickers}
        />
      </ContentWrapper>
    );
  }
  return (
    <ContentWrapper>
      <textarea placeholder="Enter your question" value={value} onChange={onTextChange} />
    </ContentWrapper>
  );
};

export const PromptNode = ({ data, id }) => {
  const colorTheme = useColorTheme();

  const testPrompt = 'List 10 companies that generate at least $1Billion in revenue from alternative energy?';
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    data.actions.updateData({ question: value });
  }, [value]);

  const onTextChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'Question'} actions={data.actions} />
      <Content id={id} data={data} value={value} onTextChange={onTextChange} />
      <Handle
        type="target"
        position="left"
        id={NODE_HANDLE_TYPES.source}
        style={{ background: colorTheme.primaryEyeCatchingCtaButton }}
      />
      <Handle type="target" position="top" id={NODE_HANDLE_TYPES.target} />
      <Handle type="source" position="bottom" />
    </NodeWrapper>
  );
};
