import React from 'react';
import { getBezierPath } from '@xyflow/react';
import { useColorTheme } from '../../../../hooks';

function CustomEdge({ id, sourceX, sourceY, targetX, targetY, style = {} }) {
  const colorTheme = useColorTheme();
  const [edgePath] = getBezierPath({ sourceX, sourceY, targetX, targetY });

  return (
    <path
      id={id}
      style={{
        stroke: colorTheme.text,
        strokeWidth: 3,
        strokeDasharray: '10,4',
        ...style,
      }}
      className="react-flow__edge-path"
      d={edgePath}
    />
  );
}

export default CustomEdge;
