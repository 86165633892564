import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { Handle } from '@xyflow/react';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { Working } from '../../../components/UI/Working';
import { JSONAnswer } from '../../DigitalAnalyst/components/AnswerLine';
import { Body5, Body7, Container } from '../../../libs/nvstr-common-ui.es';
import { useColorTheme } from '../../../hooks';
import styled from 'styled-components';
import { NODE_HANDLE_TYPES } from './index';

const ContentWrapper = styled(NodeContentWrapper)`
  min-width: 500px;
  min-height: 220px;

  textarea {
    height: 160px;
  }
`;
const CompanyListWrapper = styled.div`
  padding-top: 16px;
  padding-left: 16px;
`;
const CompanyListItemWrapper = styled.div`
  padding: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const CompanyListItem = ({ name, symbol }) => {
  return (
    <CompanyListItemWrapper>
      <Container>
        <Body5>{name}</Body5>
      </Container>
      <Container left={8}>
        <Body5>({symbol})</Body5>
      </Container>
    </CompanyListItemWrapper>
  );
};
const CompanyList = ({ data }) => {
  return (
    <CompanyListWrapper>
      {data.map((item, index) => (
        <CompanyListItem key={index} name={item.name} symbol={item.symbol} />
      ))}
    </CompanyListWrapper>
  );
};

const Content = ({ id, data, value, onTextChange }) => {
  const { answer, error, isComplete } = data;

  if (error) {
    return (
      <ContentWrapper>
        <Container bottom={8}>
          <Body5>Something went wrong.</Body5>
        </Container>
        <Body5>{error}</Body5>
      </ContentWrapper>
    );
  }
  if (answer === 'Working...') {
    return (
      <ContentWrapper>
        <Container>
          <Body7>{value}</Body7>
        </Container>
        <Working />
      </ContentWrapper>
    );
  }
  if (answer) {
    return (
      <ContentWrapper>
        <Container>
          <Body5>List Description: {value}</Body5>
        </Container>
        <JSONAnswer result={answer} renderer={(parsedList) => <CompanyList data={parsedList} />} />
      </ContentWrapper>
    );
  }
  return (
    <ContentWrapper>
      <textarea placeholder="Describe the type of list of companies" value={value} onChange={onTextChange} />
    </ContentWrapper>
  );
};

export const CompanyListNode = ({ data, id }) => {
  const colorTheme = useColorTheme();

  // major US financial institutions with the most revenue
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    data.actions.updateData({ question: value });
  }, [value]);

  const onTextChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'Company List'} actions={data.actions} />
      <Content id={id} data={data} value={value} onTextChange={onTextChange} />
      <Handle
        type="target"
        position="left"
        id={NODE_HANDLE_TYPES.source}
        style={{ background: colorTheme.primaryEyeCatchingCtaButton }}
      />
      <Handle type="target" position="top" id={NODE_HANDLE_TYPES.target} />
      <Handle type="source" position="bottom" />
    </NodeWrapper>
  );
};
