import { OutputNode } from './OutputNode';
import { DocListNode } from './DocListNode';
import { AdvancedSettingsNode } from './AdvancedSettingsNode';
import { PromptNode } from './PromptNode';
import CustomEdge from './components/CustomEdge';
import { StartNode } from './StartNode';
import { nanoid } from 'nanoid';
import { CreateNode } from './CreateNode';
import { CompanyListNode } from './CompanyListNode';
import { ForEachCompanyNode } from './ForEachCompany';

export const COMPANY_LIST_PROMPT_PREFIX =
  'do not includes any extra text, using json and responding with a list of companies where each company is an element in an array of companies, and each element is an object with the key value pairs of "name", "symbol", satisfy this description of a company list:';

export const NODE_TYPES = {
  StartNode: 'Start Node',
  CreateNode: 'Create Node',

  PromptNode: 'Question Node',
  CompanyListNode: 'Company List Node',
  ForEachCompanyNode: 'ForEach Company Node',

  OutputNode: 'Output Node',

  DocListNode: 'Document List Node',
  AdvancedSettingsNode: 'Advanced Settings Node',
};

export const EDGE_TYPES = {
  custom: CustomEdge,
};

export const NODE_CREATION_CONTEXTS = {
  Start: 'Start Node',

  BodyTargetNode: 'Body Target Node',
  BodySourceNode: 'Body Source Node',

  SourceInputNode: 'Source Input Node',
};
export const SOURCE_ONLY_NODE_TYPES = [NODE_TYPES.DocListNode, NODE_TYPES.AdvancedSettingsNode];
export const NODE_COMPONENT_TYPES = {
  [NODE_TYPES.StartNode]: StartNode,
  [NODE_TYPES.CreateNode]: CreateNode,

  [NODE_TYPES.PromptNode]: PromptNode,

  [NODE_TYPES.CompanyListNode]: CompanyListNode,
  [NODE_TYPES.ForEachCompanyNode]: ForEachCompanyNode,

  [NODE_TYPES.OutputNode]: OutputNode,

  [NODE_TYPES.DocListNode]: DocListNode,
  [NODE_TYPES.AdvancedSettingsNode]: AdvancedSettingsNode,
};

export const NODE_HANDLE_TYPES = {
  defaultTarget: 'target',
  defaultSource: 'source',

  source: 'source-input',
  target: 'target1',
};

export const generateCreationContextFrom = (connectionSourceNode) => {
  const { handleParams, type } = connectionSourceNode;
  const { handleId, handleType } = handleParams;

  if (type === NODE_TYPES.StartNode) {
    return NODE_CREATION_CONTEXTS.Start;
  }
  if (handleId === NODE_HANDLE_TYPES.source) {
    return NODE_CREATION_CONTEXTS.SourceInputNode;
  }
  const questionNodeSourceContextTypes = [
    NODE_TYPES.StartNode,
    NODE_TYPES.PromptNode,
    NODE_TYPES.CompanyListNode,
    NODE_TYPES.ForEachCompanyNode,
  ];
  if (questionNodeSourceContextTypes.includes(type) && handleType === NODE_HANDLE_TYPES.defaultSource) {
    return NODE_CREATION_CONTEXTS.BodySourceNode;
  }
  if (questionNodeSourceContextTypes.includes(type) && handleType === NODE_HANDLE_TYPES.defaultTarget) {
    return NODE_CREATION_CONTEXTS.BodyTargetNode;
  }
  return null;
};

export const createFlowNode = (type) => {
  const id = nanoid(6);
  const node = {
    id,
    type,
    dragHandle: '.custom-drag-handle',
    data: {
      id,
      actions: {},
    },
  };
  return node;
};
