import React from 'react';
import { Header } from '../components/navigation';
import styled from 'styled-components';
import { Body } from '../components/layout/Body';
import { Body1, Body5, Container, Page } from '../libs/nvstr-common-ui.es';
import { useNavigate } from 'react-router';
import { ROUTES } from '../constants/routes';
import BackArrowButton from '../components/buttons/BackArrowButton';
import { FlatButton } from '../components/buttons';
import { FormState } from '../components/UI/FormState';
import { TrackingEvent } from '../libs/nvstr-utils.es';
import { handle400Statuses, sendApiRequest } from '../services/api';
import { CenterButton } from '../components/UI/CenterButton';
import { AdvancedSettingsFields, useAdvancedSettings } from '../hooks/application/useAdvancedSettings';
import { applicationActionTypes } from '../constants/actionTypes';
import { useDispatch } from 'react-redux';
import { AdvancedSettingsButton } from '../containers/DigitalAnalyst/AdvancedSettings';
import { isValidUrl } from '../utils/usefulFuncs';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  h3 {
    text-transform: none;
  }
`;

const ButtonStyler = styled.div`
  button {
    width: 200px;
  }
`;
const Input = styled.input`
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.text};
  background-color: transparent;
  color: ${({ theme }) => theme.themeColors.text};

  width: 100%;
  padding: 8px;
  max-width: 600px;
`;

const defaultAdvancedSettings = {
  enableJS: true,
  numOfPages: 20,
  version: 2,
};

const advancedSettingsFields = [
  AdvancedSettingsFields.enableJS,
  AdvancedSettingsFields.numOfPages,
  AdvancedSettingsFields.version,
];

function WebpageUploader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState('https://');
  const [formError, setFormError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [wasSuccess, setWasSuccess] = React.useState(false);
  const { enableJS, numOfPages, version } = useAdvancedSettings();

  React.useEffect(() => {
    dispatch({
      type: applicationActionTypes.setAdvancedSettings,
      payload: defaultAdvancedSettings,
    });
  }, []);

  const onGoBack = () => navigate(ROUTES.home);

  const isValid = (v) => {
    return isValidUrl(v);
  };
  const submit = async (v) => {
    const form = { url: v, run_js: enableJS, max_pages: numOfPages, crawl_version: version };

    TrackingEvent.create('Asked Digital Analyst To Summarize Website', {
      Value: v,
    });

    setIsSubmitting(true);
    setFormError(null);

    const URL = `v1/genai_process_web_page`;
    const { status, data, error } = await sendApiRequest('post', URL, form);
    setIsSubmitting(false);
    handle400Statuses(status);
    if (status === 200) {
      setWasSuccess(true);
    } else {
      setWasSuccess(false);
      setFormError(data?.error || error || 'Something went wrong, try again.');
    }
  };

  const onChange = (e) => {
    const v = e.target.value;
    const prefix = 'https://';

    if (v.slice(0, 8) !== prefix) {
      setValue(prefix);
    } else {
      setValue(v);
    }
  };
  const handleProcessClick = () => {
    if (isValid(value)) {
      submit(value);
    } else {
      setFormError('Please enter a valid url');
    }
  };

  if (wasSuccess) {
    return (
      <PageWrapper>
        <Header />
        <Body withFooter>
          <Container>
            <Page width={'600px'}>
              <BackArrowButton onClick={onGoBack} />
              <Container top={48}>
                <Body1 bold>Process request received</Body1>
              </Container>
              <Container top={48}>
                <Body5>
                  Digital Analyst is processing the website, this normally takes a few minutes. Once complete, in Query
                  Mode you will be able to ask questions on the website which will appear as a selection in your
                  documents section.
                </Body5>
              </Container>
              <Container top={48}>
                <CenterButton>
                  <FlatButton fullWidth onClick={onGoBack}>
                    Dismiss
                  </FlatButton>
                </CenterButton>
              </Container>
            </Page>
          </Container>
        </Body>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Header />
      <Body withFooter>
        <Container>
          <Page width={'600px'}>
            <BackArrowButton onClick={onGoBack} />
            <AdvancedSettingsButton fields={advancedSettingsFields} />
            <Container top={48}>
              <Container>
                <Body1 bold>Enter Website</Body1>
                <Container top={24}>
                  <Input value={value} onChange={onChange} autoFocus />
                </Container>
              </Container>
            </Container>

            <Container top={24}>
              <FormState error={formError} isSubmitting={isSubmitting} text={'Working...'} />
              <Container top={16}>
                <ButtonStyler>
                  <CenterButton>
                    <FlatButton fullWidth onClick={handleProcessClick}>
                      Process Website
                    </FlatButton>
                  </CenterButton>
                </ButtonStyler>
              </Container>
            </Container>
          </Page>
        </Container>
      </Body>
    </PageWrapper>
  );
}

export default WebpageUploader;
