import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { Handle } from '@xyflow/react';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { SkeletonButton } from '../../../components/buttons/SkeletonButton';
import { useColorTheme } from '../../../hooks';
import styled from 'styled-components';
import { Body5, Container } from '../../../libs/nvstr-common-ui.es';

const ContentWrapper = styled.div`
  padding: 12px 0 16px 0;

  button {
    padding: 6px 12px;
  }
`;

export const DocListNode = ({ data, id }) => {
  const colorTheme = useColorTheme();

  const hc = () => {
    data.actions.onShowDocumentSelection();
  };

  const documentsSelected = data.selectedDocs;

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'Documents List'} actions={data.actions} />
      <NodeContentWrapper>
        <ContentWrapper>
          <Container bottom={16} left={2}>
            <Body5 bold>{`${documentsSelected.length} Document${
              documentsSelected.length === 1 ? '' : 's'
            } Selected`}</Body5>
          </Container>
          <SkeletonButton onClick={hc}>Select Documents</SkeletonButton>
        </ContentWrapper>
      </NodeContentWrapper>

      <Handle type="source" position="right" style={{ background: colorTheme.primaryEyeCatchingCtaButton }} />
    </NodeWrapper>
  );
};
