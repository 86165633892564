import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { Body7 } from '../../../libs/nvstr-common-ui.es';
import styled from 'styled-components';
import { NODE_CREATION_CONTEXTS, NODE_TYPES } from './index';

const ContentWrapper = styled(NodeContentWrapper)`
  min-width: 180px;
  width: 180px;

  padding: 0;
  padding-left: 0;
  padding-right: 0;
`;
const NodeSelectorWrapper = styled.div`
  //padding-top: 8px;
`;
const NodeOptionWrapper = styled.div`
  cursor: pointer;
  padding: 6px 8px 8px 8px;
  border-top: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};

  &:hover {
    opacity: 0.8;
    background: ${({ theme }) => theme.themeColors.text};

    * {
      color: ${({ theme }) => theme.themeColors.componentNoOpacity};
    }
  }
`;

function getAvailableNodes(creationContext, connectionStartSourceNodeType) {
  if (creationContext === NODE_CREATION_CONTEXTS.Start) {
    return [NODE_TYPES.PromptNode, NODE_TYPES.CompanyListNode];
  }
  if (creationContext === NODE_CREATION_CONTEXTS.BodySourceNode) {
    const defaultNodes = [NODE_TYPES.PromptNode, NODE_TYPES.CompanyListNode, NODE_TYPES.OutputNode];

    if (connectionStartSourceNodeType === NODE_TYPES.CompanyListNode) {
      return [NODE_TYPES.ForEachCompanyNode, ...defaultNodes];
    }

    return defaultNodes;
  }
  if (creationContext === NODE_CREATION_CONTEXTS.BodyTargetNode) {
    return [NODE_TYPES.PromptNode, NODE_TYPES.CompanyListNode];
  }
  if (creationContext === NODE_CREATION_CONTEXTS.SourceInputNode) {
    return [NODE_TYPES.DocListNode, NODE_TYPES.AdvancedSettingsNode];
  }
  return [];
}

const NodeOption = ({ onClick, children }) => (
  <NodeOptionWrapper onClick={onClick}>
    <Body7 bold>{children}</Body7>
  </NodeOptionWrapper>
);

const NodeSelector = ({ onSelect, creationContext, connectionStartSourceNodeType }) => {
  const [nodes, setNodes] = React.useState(getAvailableNodes(creationContext, connectionStartSourceNodeType));

  React.useEffect(() => {
    setNodes(getAvailableNodes(creationContext, connectionStartSourceNodeType));
  }, [creationContext, connectionStartSourceNodeType]);

  return (
    <NodeSelectorWrapper>
      {nodes.map((node) => (
        <NodeOption key={node} onClick={() => onSelect(node)}>
          {node}
        </NodeOption>
      ))}
    </NodeSelectorWrapper>
  );
};

export const CreateNode = ({ data, id }) => {
  const { sourceNodeId, creationContext, connectionStartSourceNode } = data;
  const { handleParams: connectionStartHandleParams, type: connectionStartSourceNodeType } = connectionStartSourceNode;

  const onSelectNode = (sourceNodeId, currentNodeId, type) => {
    data.actions.replaceNode(sourceNodeId, currentNodeId, type, connectionStartHandleParams);
  };

  const bindOnSelectNode = (sourceNodeId, currentNodeId) => {
    return (type) => onSelectNode(sourceNodeId, currentNodeId, type);
  };

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'Add Node'} actions={{ onRemove: data.actions.onRemove }} />
      <ContentWrapper>
        <NodeSelector
          onSelect={bindOnSelectNode(sourceNodeId, id)}
          creationContext={creationContext}
          connectionStartSourceNodeType={connectionStartSourceNodeType}
        />
      </ContentWrapper>
    </NodeWrapper>
  );
};
