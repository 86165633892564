import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { Handle } from '@xyflow/react';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { Working } from '../../../components/UI/Working';
import { Answer } from '../../DigitalAnalyst/components/AnswerLine';
import { AI_TOOLS } from '../../../constants';
import { Body5, Body7, Container } from '../../../libs/nvstr-common-ui.es';
import { useColorTheme } from '../../../hooks';
import styled from 'styled-components';
import { NODE_HANDLE_TYPES } from './index';

const ContentWrapper = styled(NodeContentWrapper)`
  min-width: 700px;
  min-height: 420px;
`;
const CompanyAnswerWrapper = styled.div`
  padding-top: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};
`;

const Content = ({ id, data, value, onTextChange }) => {
  const { answers, error, isComplete } = data;

  if (error) {
    return (
      <ContentWrapper>
        <Container bottom={8}>
          <Body5>Something went wrong.</Body5>
        </Container>
        <Body5>{error}</Body5>
      </ContentWrapper>
    );
  }
  if (!!answers && Object.keys(answers).length === 0) {
    return (
      <ContentWrapper>
        <Container>
          <Body7>{value}</Body7>
        </Container>
        <Working />
      </ContentWrapper>
    );
  }
  if (answers) {
    const companySymbols = Object.keys(answers);
    return (
      <ContentWrapper>
        <Container bottom={16}>
          <Body5>Question Asked: {value}</Body5>
        </Container>
        {companySymbols.map((symbol, index) => (
          <CompanyAnswerWrapper key={index}>
            <Container bottom={0}>
              <Body5>{symbol}:</Body5>
            </Container>
            <Answer
              result={answers[symbol]}
              userMode={AI_TOOLS.QUERY_ENGINE}
              isComplete={isComplete}
              // isShowingCitations={isShowingCitations}
              // onCitationClick={onCitationClick}
              // isAddingCitations={citationsResultId && !citationsStreamEnded && citationsStreamingResult !== 'Working...'}
              // enableMoreDetail={enableMoreDetail && streamEnd}
              // model={model}
              // selectedDocs={selectedDocs}
              // sourceDocs={sourceDocs}
              // tickers={tickers}
            />
          </CompanyAnswerWrapper>
        ))}
      </ContentWrapper>
    );
  }
  return (
    <ContentWrapper>
      <textarea placeholder="Enter your question" value={value} onChange={onTextChange} />
    </ContentWrapper>
  );
};

export const ForEachCompanyNode = ({ data, id }) => {
  const colorTheme = useColorTheme();

  const start = ' what is their technology spend?';
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    data.actions.updateData({ question: value });
  }, [value]);

  const onTextChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'For Each Company Question'} actions={data.actions} />
      <Content id={id} data={data} value={value} onTextChange={onTextChange} />
      <Handle
        type="target"
        position="left"
        id={NODE_HANDLE_TYPES.source}
        style={{ background: colorTheme.primaryEyeCatchingCtaButton }}
      />
      <Handle type="target" position="top" id={NODE_HANDLE_TYPES.target} />
      <Handle type="source" position="bottom" />
    </NodeWrapper>
  );
};
