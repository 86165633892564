import React from 'react';
import { NodeHeader } from './components/NodeHeader';
import { Handle } from '@xyflow/react';
import { NodeContentWrapper, NodeWrapper } from './styles';
import { Body5 } from '../../../libs/nvstr-common-ui.es';
import { useColorTheme } from '../../../hooks';

export const AdvancedSettingsNode = ({ data, id }) => {
  const colorTheme = useColorTheme();

  const [value, setValue] = React.useState('');

  return (
    <NodeWrapper data-id={id}>
      <NodeHeader id={id} name={'Advanced Settings'} actions={data.actions} />
      <NodeContentWrapper>
        <Body5>Settings Fields Here</Body5>
      </NodeContentWrapper>

      <Handle type="source" position="right" style={{ background: colorTheme.primaryEyeCatchingCtaButton }} />
    </NodeWrapper>
  );
};
